<template>
  <div>
    <div :style="getElementStyle">
      <!-- Below code for Before Field Style -->

      <div v-if="!isDefalutPosq">
        <div style="display: flex; align-items: unset">
          <label for="text" style="white-space: nowrap; margin-right: 5px">{{
            data.label ? data.label : data.input_type
          }}</label>
          <span class="info-left" v-if="data.description">
            <i
              class="el-icon-info"
              :title="data.description"
              style="margin-right: 5px"
            />
          </span>
          <span>
            <i
              class="el-icon-copy-document"
              v-if="!isDataTableField"
              @click="copyDocument"
            ></i
          ></span>
          <span class="setting-icon" @click="openSettings">
            <i class="el-icon-s-tools" />
          </span>
          <input
            :style="{ 'text-align': inputTextAlignment, ...getStyle }"
            :placeholder="data.placeholder"
            v-model="data.formula"
            :suffix-icon="data.properties.el_icon"
            class="ffv"
          />
        </div>
      </div>
      <!-- Below Code for Top Label Style -->

      <el-row align="middle" v-else>
        <el-col
          v-if="!isInDataTable"
          :span="showLabel && !isDefalutPosq ? 4 : data.description ? 8 : 24"
          :style="computedStyles"
          class="bottom-space"
        >
          {{ data.label ? data.label : data.input_type }}
          <i
            class="el-icon-copy-document"
            v-if="!isDataTableField"
            @click="copyDocument"
          ></i>
          <span>
            <el-popover
              v-if="data.description"
              placement="top-start"
              trigger="hover"
              :content="data.description"
            >
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </span>
        </el-col>
        <el-row v-if="isActive || isDataTableField" class="setting-icon">
          <i class="el-icon-s-tools" @click="openSettings"></i>
        </el-row>

        <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
          <div>
            <input
              :style="{ 'text-align': inputTextAlignment, ...getStyle }"
              :placeholder="data.placeholder"
              v-model="data.formula"
              :suffix-icon="data.properties.el_icon"
              class="ffv"
            />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormulaView",
  components: {},
  props: ["data", "value", "isActive", "isDataTableField", "isInDataTable"],
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getStyle() {
      return `height:${this.data.height - 50}px`;
    },
    computedStyles() {
      const styles = {};
      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    inputTextAlignment: {
      get() {
        return this.data.styles && this.data.styles.inputTextAlignments
          ? this.data.styles.inputTextAlignments
          : "left"; // Default value if not provided
      },
      set(value) {
        if (this.data.styles) {
          this.data.styles.inputTextAlignments = value;
        } else {
          this.$set(this.data, "styles", { inputTextAlignments: value });
        }
      },
    },
    // Tharuni Start
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";

        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
  },
  // Tharuni  End
  data() {
    return {
      validations: [],
      isDefalutPos: true,
      showLabel: true,
    };
  },
  methods: {
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    openSettings() {
      this.$emit("settings");
    },
  },
  mounted() {
    if (this.data.styles) {
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
      }
    }
  },
  watch: {
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.inputTextAlignment = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
  },
};
</script>

<style lang="scss">
.info-right {
  margin-left: 115px;
}
</style>

<style lang="scss" scope>
.ffv {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: inherit;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
</style>