var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.getElementStyle)},[(!_vm.isDefalutPos)?_c('div',[_c('div',{staticStyle:{"display":"flex","align-items":"unset"}},[(_vm.isIconRequired)?_c('icons',{staticClass:"mr-1",attrs:{"iconName":_vm.data.icon_name}}):_vm._e(),_c('label',{staticStyle:{"white-space":"nowrap","margin-right":"5px"},attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label))]),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-right":"5px"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(!_vm.isDataTableField)?_c('i',{staticClass:"el-icon-copy-document",on:{"click":_vm.copyDocument}}):_vm._e()]),(_vm.data.description)?_c('span',{staticStyle:{"margin-right":"5px"}},[_c('i',{staticClass:"el-icon-info",attrs:{"title":_vm.data.description}})]):_vm._e(),(
          _vm.data.input_type == 'MULTI_LINE_TEXT' ||
          _vm.data.input_type == 'PAYMENT_VARIABLE'
        )?[_c('el-input',{style:({ 'text-align': _vm.inputTextAlignment(), ..._vm.getStyle }),attrs:{"type":"textarea","rows":_vm.data.validations.rows,"resize":"none","placeholder":_vm.data.placeholder,"suffix-icon":_vm.data.properties.el_icon},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})]:_vm._e(),(_vm.data.input_type == 'RICH_TEXT')?[(!_vm.isDataTableField)?_c('TextEditor',{attrs:{"data":_vm.selectedValue}}):_c('el-link',[_vm._v("Text Editor")])]:_vm._e()],2),_c('div',[(_vm.isActive || _vm.isDataTableField)?_c('span',{staticClass:"setting-icon"},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e()])]):_c('el-row',{attrs:{"align":"middle"}},[(!_vm.isInDataTable)?_c('el-col',{ref:"colRef",staticClass:"bottom-space",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[(_vm.isIconRequired)?_c('icons',{staticClass:"mr-1",attrs:{"iconName":_vm.data.icon_name}}):_vm._e(),_c('label',{attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label))]),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(!_vm.isDataTableField)?_c('i',{staticClass:"el-icon-copy-document",on:{"click":_vm.copyDocument}}):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)],1):_vm._e(),_c('el-col',{attrs:{"span":_vm.showLabel && _vm.isDefalutPos ? 4 : 0}}),(_vm.isActive || _vm.isDataTableField)?_c('el-row',{staticClass:"setting-icon"},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e(),_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 12 : 24}},[_c('div',[(
            _vm.data.input_type == 'MULTI_LINE_TEXT' ||
            _vm.data.input_type == 'PAYMENT_VARIABLE'
          )?[_c('el-input',{class:{ datatable: _vm.isDataTableField },style:({ 'text-align': _vm.inputTextAlignment(), ..._vm.getStyle }),attrs:{"type":"textarea","rows":_vm.data.validations.rows,"resize":"none","placeholder":_vm.data.placeholder,"suffix-icon":_vm.data.properties.el_icon},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})]:_vm._e(),(_vm.data.input_type == 'RICH_TEXT')?[(!_vm.isDataTableField)?_c('ckeditor',{attrs:{"data":_vm.selectedValue,"config":_vm.editorConfigWithOptions}}):_c('el-link',{attrs:{"type":"primary"}},[_vm._v("CK Text Editor")])]:_vm._e()],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }